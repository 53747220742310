import { render, staticRenderFns } from "./FormProfileCreation.vue?vue&type=template&id=05d8f1f5&scoped=true&"
import script from "./FormProfileCreation.vue?vue&type=script&lang=ts&"
export * from "./FormProfileCreation.vue?vue&type=script&lang=ts&"
import style0 from "./FormProfileCreation.vue?vue&type=style&index=0&id=05d8f1f5&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05d8f1f5",
  null
  
)

export default component.exports