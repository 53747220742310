var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-account-creation-container"},[_c('FormWrapper',{attrs:{"whitelisted":true}},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"onsubmit":"event.preventDefault()"}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-create-headline"},[_vm._v(" "+_vm._s(_vm.$t('choose_profile_name_and_password'))+" ")])]),_c('FormRow',{attrs:{"vertical":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('set_account_name'))+" ")]},proxy:true},{key:"inputs",fn:function(){return [_c('ValidationProvider',{staticClass:"inputs-create-container items-container",attrs:{"vid":"newAccountName","name":_vm.$t('profile_name'),"rules":_vm.validationRules.newAccountName,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ErrorTooltip',{attrs:{"errors":errors}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formItems.profileName),expression:"formItems.profileName"}],ref:"passwordInput",staticClass:"input-size input-style",attrs:{"type":"text"},domProps:{"value":(_vm.formItems.profileName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.formItems, "profileName", $event.target.value)},_vm.stripTagsProfile]}})])]}}],null,true)})]},proxy:true}],null,true)}),_c('FormRow',{attrs:{"vertical":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('set_network_type'))+" ")]},proxy:true},{key:"inputs",fn:function(){return [_c('ValidationProvider',{staticClass:"select-container",attrs:{"vid":"selectedMosaic","name":_vm.$t('network_type'),"rules":'required',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ErrorTooltip',{attrs:{"errors":errors}},[_c('div',{staticClass:"inputs-create-container select-container"},[_c('Select',{staticClass:"select-size select-style",attrs:{"placeholder":_vm.$t('choose_network')},model:{value:(_vm.formItems.networkType),callback:function ($$v) {_vm.$set(_vm.formItems, "networkType", $$v)},expression:"formItems.networkType"}},_vm._l((_vm.networkTypeList),function(item,index){return _c('Option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)])]}}],null,true)})]},proxy:true}],null,true)}),_c('FormRow',{attrs:{"vertical":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('new_password_label'))+" ")]},proxy:true},{key:"inputs",fn:function(){return [_c('ValidationProvider',{staticClass:"inputs-create-container select-container",attrs:{"vid":"newPassword","mode":"lazy","name":_vm.$t('password'),"rules":_vm.validationRules.password,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ErrorTooltip',{attrs:{"errors":errors}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formItems.password),expression:"formItems.password"}],ref:"passwordInput",staticClass:"input-size input-style",attrs:{"type":"password","placeholder":_vm.$t('please_enter_your_account_password')},domProps:{"value":(_vm.formItems.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formItems, "password", $event.target.value)}}})])]}}],null,true)})]},proxy:true}],null,true)}),_c('FormRow',{attrs:{"vertical":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('repeat_password_label'))+" ")]},proxy:true},{key:"inputs",fn:function(){return [_c('ValidationProvider',{staticClass:"inputs-create-container items-container",attrs:{"vid":"confirmPassword","name":_vm.$t('confirmPassword'),"rules":_vm.validationRules.confirmPassword,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ErrorTooltip',{attrs:{"errors":errors}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formItems.passwordAgain),expression:"formItems.passwordAgain"}],staticClass:"input-size input-style",attrs:{"type":"password","data-vv-name":"confirmPassword","placeholder":_vm.$t('please_enter_your_new_password_again')},domProps:{"value":(_vm.formItems.passwordAgain)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formItems, "passwordAgain", $event.target.value)}}})])]}}],null,true)})]},proxy:true}],null,true)}),_c('FormRow',{attrs:{"vertical":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('password_hint'))+" ")]},proxy:true},{key:"inputs",fn:function(){return [_c('ValidationProvider',{staticClass:"inputs-create-container items-container",attrs:{"vid":"hint","name":_vm.$t('hint'),"rules":_vm.validationRules.message,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ErrorTooltip',{attrs:{"errors":errors}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formItems.hint),expression:"formItems.hint"}],staticClass:"input-size input-style",domProps:{"value":(_vm.formItems.hint)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.formItems, "hint", $event.target.value)},_vm.stripTagsProfile]}})])]}}],null,true)})]},proxy:true}],null,true)}),_c('div',{staticClass:"form-line-container form-row buttons-row mt-3"},[_c('button',{staticClass:"solid-button button-style create-account-style",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'profiles.importProfile.importStrategy' })}}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")]),_c('button',{staticClass:"inverted-button button-style create-account-style",attrs:{"type":"submit"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" "+_vm._s(_vm.$t('next'))+" ")])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }