





















































































































































import { FormProfileCreationTs } from './FormProfileCreationTs';
export default class FormProfileCreation extends FormProfileCreationTs {}
